import { ethers } from 'ethers'
import { chain } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { Buffer } from 'buffer'

// Adds polyfill Buffer for client
if (typeof window !== 'undefined' && !window.Buffer) window.Buffer = Buffer

export const PolygonAlchemy =
  'https://polygon-mainnet.g.alchemy.com/v2/dQev3JnNjnO_LbRZ966iBpVVOh1KKo9J'

export const PolygonAlchemyMumbai =
  'https://polygon-mumbai.g.alchemy.com/v2/7-JdW5r4plvD14_IqlX4WKDjQE_2rMLi'

export const EthersAlchemy =
  'https://eth-mainnet.alchemyapi.io/v2/9165B5y_1pSCD_pvcHDHtzRbe-Mbp4p6'

const currentChain =
  process.env.NODE_ENV === 'production'
    ? chain.polygonMainnet
    : chain.polygonTestnetMumbai

export const connectors = [
  new InjectedConnector({
    chains: [currentChain],
  }),
  new WalletConnectConnector({
    options: {
      qrcode: true,
      clientMeta: {
        name: 'factory-workers',
        description: 'Factory Workers Robots',
        url: 'nft-factory.club',
        icons: ['/assets/images/common/logo-violet.png'],
      },
    },
  }),
]

export const provider = new ethers.providers.JsonRpcProvider({
  url: EthersAlchemy,
})

export const contractAddress = '0xfbbeA8F90152F2FF17014B332f2f2053F85D4c21'

export const aofContractAddress = '0x537647677540F307959743394c4D311C63c85190'
export const specialAOFContractAddress =
  '0x9714e7469A0fb149e7676fD16aDC3867fCceCc1c'
