import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MetamaskButton from '../Inputs/MetamaskButton'
import PrimaryButton from '../Inputs/PrimaryButton'
import BaseModal from './BaseModal'

interface ISelectConnectModal {
  isVisible: boolean
  metamask: () => void
  walletConnect: () => void
  closeModal: () => void
}

const SelectConnectModal: React.FC<ISelectConnectModal> = ({
  isVisible,
  metamask,
  walletConnect,
  closeModal,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const body = document.querySelector('body')

    if (isVisible) body?.classList.add('overflow-hidden')
    else body?.classList.remove('overflow-hidden')
  }, [isVisible])

  return (
    <BaseModal isOpen={isVisible} closeModal={closeModal}>
      <h2 className='text-white text-lg md:text-3xl font-semibold text-center md:w-2/3 mt-4'>
        {t('selectConnection.title')}
      </h2>
      <div className='w-full flex flex-col justify-start items-center mt-4'>
        <PrimaryButton
          full
          title='Wallet Connect'
          onClick={() => {
            closeModal()
            setTimeout(() => {
              walletConnect()
            }, 500)
          }}
        />
        <div className='mt-4 flex flex-row justify-center items-center w-full px-5'>
          <span
            className='w-32'
            style={{ borderTop: '2px solid white' }}
          ></span>
          <h4 className='text-white text-l font-semibold mx-3 text-center'>
            OR
          </h4>
          <span
            className='w-32'
            style={{ borderTop: '2px solid white' }}
          ></span>
        </div>
        <MetamaskButton
          title='METAMASK'
          full
          onClick={() => {
            closeModal()
            setTimeout(() => {
              metamask()
            }, 500)
          }}
        />
      </div>
    </BaseModal>
  )
}

export default SelectConnectModal
