import { useCallback, useEffect, useState } from 'react'

const useMediaQuery = (width: number) => {
  const [targetReached, setTargetReached] = useState(-1)

  const updateTarget = useCallback((e: any) => {
    if (e.matches) {
      setTargetReached(1)
    } else {
      setTargetReached(0)
    }
  }, [])

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    media.addEventListener('change', updateTarget)

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(1)
    }

    if (!media.matches) {
      setTargetReached(0)
    }

    return () => media.removeEventListener('change', updateTarget)
  }, [])

  return targetReached
}

export default useMediaQuery
